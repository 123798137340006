<template>
  <div
    class="group relative px-2"
    :class="{ 'animate-pulse': loading }"
    :data-testid="`product-card-${id}`"
  >
    <article
      :id="`product-${id}`"
      v-element-visibility="onVisible"
      class="mx-auto max-w-72"
    >
      <slot name="header-actions">
        <div class="mt-2 flex items-center justify-between pt-1">
          <ProductBadges :product="product" />
          <div class="flex gap-1">
            <DiscoverSimilar
              v-if="image"
              :variant-or-product-id="syteVariantId || id"
              :product-image="image"
              :placement="placement"
            />
            <div @click.stop>
              <WishlistToggle
                :key="`product-${id}-wishlist-toggle`"
                :product="product"
              />
            </div>
          </div>
        </div>
      </slot>

      <slot name="header">
        <div
          class="group relative flex h-screen max-h-52 items-start justify-start p-3"
        >
          <slot name="header-image" :image="image">
            <component
              :is="disableLink ? 'div' : NuxtLink"
              v-bind="!disableLink ? { to: localizedDetailLink } : {}"
              :data-prefetch="disableLink ? false : true"
            >
              <ProductImage
                v-if="image"
                :image="image"
                :alt="`${name} ${title}`"
                sizes="272px"
                :should-trim="true"
                class="absolute inset-0 cursor-pointer p-2 transition duration-200"
                :class="{
                  'opacity-40': !isAvailable,
                }"
                @click="$emit('click:select-item', id)"
              />
            </component>
          </slot>
        </div>
      </slot>
      <ProductPromotionBanners
        v-if="showPromotions"
        class="mb-2"
        item-class="text-center flex justify-center flex-wrap gap-y-0 !py-1.5"
        :product="product"
        view="category"
      />
      <div v-if="showDescription">
        <slot name="description" v-bind="$props">
          <footer :class="footerClasses">
            <div>
              <NuxtLink :to="localizedDetailLink">
                <p
                  v-if="title"
                  class="text-base font-medium uppercase leading-tight"
                >
                  {{ title }}
                </p>
                <p class="text-base leading-tight">
                  {{ name }}
                </p>
                <BazaarVoice :id="id" show="inline_rating" />
                <p
                  v-if="localizedSiblingCountKey"
                  class="mb-2.5 mt-2 text-[15px] leading-[1.2]"
                >
                  <AsyncLocalizedText
                    :i18n-key="localizedSiblingCountKey"
                    :i18n-args="localizedSiblingCountArgs"
                  >
                  </AsyncLocalizedText>
                </p>
                <slot name="description-price" :price="price">
                  <ProductPrice
                    v-if="
                      (combinedPrice && rxConfiguratorEnabled) || hasPriceRange
                    "
                    :price="combinedPrice"
                    :show-price-from="true"
                    :applied-reductions="
                      rxConfiguratorEnabled
                        ? combinedPrice.appliedReductions
                        : priceRange?.min?.appliedReductions
                    "
                    :show-automatic-discount="!isBuyXGetYPrioritized"
                    type="loud"
                    :zero-cost-product="isZeroCostProduct"
                    size="md"
                    :class="priceAlignClasses"
                  />
                  <ProductPrice
                    v-else-if="price"
                    :price="price"
                    :applied-reductions="price.appliedReductions"
                    :show-automatic-discount="!isBuyXGetYPrioritized"
                    :zero-cost-product="isZeroCostProduct"
                    type="loud"
                    size="md"
                    :class="priceAlignClasses"
                    :show-price-from="hasVariantsWithDifferentPrice"
                  />
                  <div v-if="isZeroCostProduct" class="max-w-3xs text-xs">
                    {{ $t('zero_cost_frames.price_hint') }}
                  </div>
                  <span
                    v-if="lowestPrice"
                    :class="`${priceAlignClasses} text-xs text-gray-600`"
                  >
                    {{ $t('pdp.lowest_price') }}
                    {{ `${lowestPrice}` }}
                  </span>
                  <span
                    v-if="rxConfiguratorEnabled && lensesName"
                    :class="`${priceAlignClasses} text-xs`"
                  >
                    {{
                      $t('pdp.with_cheapest_compatible_lenses', {
                        lensesName,
                      })
                    }}
                  </span>
                  <span
                    v-if="
                      !hasVariantsWithDifferentPrice &&
                      isCareProduct &&
                      careProductBasePriceText
                    "
                    :class="`${priceAlignClasses} text-xs text-gray-600`"
                  >
                    {{ careProductBasePriceText }}
                  </span>
                </slot>
              </NuxtLink>
              <slot name="links"></slot>
            </div>
            <slot name="footer-action"></slot>
          </footer>
        </slot>
      </div>
    </article>
  </div>
</template>

<script setup lang="ts">
import type { Product, Price as BapiPrice } from '@scayle/storefront-nuxt'
import { getFirstAttributeValue } from '@scayle/storefront-nuxt'
import { useProductPrice } from '~/composables/useProductPrice'
import { vElementVisibility } from '@vueuse/components'
import { NuxtLink } from '#components'

const props = defineProps({
  product: {
    type: Object as PropType<Product>,
    required: true,
  },
  loading: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  footerClasses: {
    type: String as PropType<string>,
    default: 'p-4 pt-1 flex justify-center text-center',
  },
  priceAlignClasses: {
    type: String as PropType<string>,
    default: 'flex justify-center',
  },
  isAvailable: {
    type: Boolean as PropType<boolean>,
    default: true,
  },
  placement: {
    type: String as PropType<SyteValidPlacement>,
    required: true,
  },
  showPromotions: {
    type: Boolean as PropType<boolean>,
    default: true,
  },
  showDescription: {
    type: Boolean as PropType<boolean>,
    default: true,
  },
  disableLink: {
    type: Boolean,
    default: false,
  },
})
const $emit = defineEmits(['click:select-item', 'intersect:product'])

const nuxtApp = useNuxtApp()
const { $currentShop, $i18n, $fimLocalePath, $featureToggle } = nuxtApp

const { isBuyXGetYPrioritized } = await useProductPromotions(props.product)

const { getProductDetailRoute } = useRouteHelpers()

const { isRxConfiguratorEnabled, isZeroCostEnabled } = $featureToggle
const rxConfiguratorEnabled = computed(() =>
  isRxConfiguratorEnabled(props.product),
)
const {
  getCheapestLensForFrame,
  getCombinedProductPrice,
  getCombinedLowestPriorPrice,
} = await useProductPrice()
const { product: lensProduct } = getCheapestLensForFrame(props.product)

const siblingCount = computed(() =>
  getProductSiblingCount(props.product, $currentShop),
)

const onVisible = (state: boolean) => {
  if (!state) {
    return
  }
  $emit('intersect:product', props.product.id)
}

const hasVariantsWithDifferentPrice = computed(() => {
  if (rxConfiguratorEnabled.value) {
    return true
  }

  // Contact lens with multiple package sizes
  if (
    isContactLens(props.product) &&
    siblingCount.value &&
    siblingCount.value > 1
  ) {
    return true
  }

  const validVariants =
    props.product?.variants?.filter((v) => {
      return (
        Boolean(v?.stock?.quantity) || Boolean(v?.stock?.isSellableWithoutStock)
      )
    }) ?? []

  const allPrices = validVariants?.map((variant) => variant.price.withTax) ?? []

  const allUniquePricesSet = new Set(allPrices)

  return allUniquePricesSet.size > 1
})

const syteVariantId = computed(() => {
  const variant = getVariantWithLowestPrice(props.product.variants ?? [])
  return variant?.id
})

// Small hack to show sizes instead of colors for everything located in category 3 (contact-lenses)
// const localizedSiblingCount = computed(() => {
//   if (siblingCount.value && siblingCount.value > 1) {
//     const translationKey = isContactLens(props.product)
//       ? 'plurals.size_count'
//       : 'plurals.color_count'
//     return getLocalizedText($i18n, translationKey, {
//       count: siblingCount.value,
//     })
//   }

//   return undefined
// })

const localizedSiblingCountKey = computed(() => {
  if (siblingCount.value && siblingCount.value > 1) {
    return isContactLens(props.product)
      ? 'plurals.size_count'
      : 'plurals.color_count'
  }

  return undefined
})
const localizedSiblingCountArgs = computed(() => ({
  count: siblingCount.value,
}))

const priceRange = computed(() => {
  const getMin = (p: Product) => p?.priceRange?.min
  const getMax = (p: Product) => p?.priceRange?.max

  const compare = (a: BapiPrice | undefined, b: BapiPrice | undefined) => {
    if (a && b && a.withTax > b.withTax) {
      return 1
    } else if (a && b && a.withTax === b.withTax) {
      return 0
    } else {
      return -1
    }
  }
  const initial = { min: getMin(props.product), max: getMax(props.product) }

  return (
    props.product?.siblings?.reduce((result, sibling) => {
      const min = getMin(sibling)
      const max = getMax(sibling)
      return {
        min: compare(result.min, min) > 0 ? min : result.min,
        max: compare(result.max, max) > 0 ? result.max : max,
      }
    }, initial) ?? initial
  )
})

const hasPriceRange = computed(
  () =>
    (priceRange.value.min?.withTax ?? Infinity) <
    (priceRange.value.max?.withTax ?? 0),
)

const price = computed(() => getCombinedProductPrice(props.product))

const image = computed(() => getPreviewImage(props.product))

const combinedPrice = computed(() => {
  if (rxConfiguratorEnabled.value) {
    return unref(getCombinedProductPrice(props.product))
  }
  return price.value
})

const isZeroCostProduct = computed(() => {
  return isZeroCostEnabled() && combinedPrice.value.withoutTax === 0
})

const lowestPrice = computed(() => {
  const combinedLowestPriorPrice = getCombinedLowestPriorPrice(
    unref(props.product),
  )

  return getLowestPriorPrice(combinedPrice.value, combinedLowestPriorPrice)
})

const careProductBasePriceText = computed(() => {
  if (props.product?.variants) {
    const packageSize = getFirstAttributeValue(
      props.product.variants[0].attributes,
      'packingTypeDescription',
    )?.label
    const referencePriceWithTax =
      props.product.variants[0].price?.reference?.withTax
    if (packageSize && referencePriceWithTax) {
      return getBasePriceFormatted(
        packageSize,
        referencePriceWithTax,
        $i18n.t('litre'),
      )
    }
  }
  return ''
})

const localizedDetailLink = computed(() =>
  $fimLocalePath(getProductDetailRoute(props.product)),
)
const title = computed(() =>
  getFirstAttributeLabel(props.product.attributes, 'brand'),
)
const name = computed(() =>
  getFirstAttributeLabel(props.product.attributes, 'name'),
)
const id = computed(() => props.product.id)
const lensesName = getLensName(lensProduct)
const isCareProduct = computed(() => isCareProducts(props.product))
</script>

<template>
  <span>
    {{ message }}
  </span>
</template>

<script setup lang="ts">
import type { PrimitiveType } from 'intl-messageformat'

type Props = {
  i18nKey: string
  i18nArgs?: Record<string, PrimitiveType>
}

const props = withDefaults(defineProps<Props>(), {
  i18nArgs: undefined,
})

const { $i18n } = useNuxtApp()

const { IntlMessageFormat } = await import('intl-messageformat')

const lang = getIsoLanguage($i18n.locale.value)
const msgTemplate = new IntlMessageFormat($i18n.t(props.i18nKey), lang)

const message = computed(() => msgTemplate.format<string>(props.i18nArgs))
</script>
